import React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { useCollectionState, useCurrencyState, useTokenState } from "src/state";
import { round } from "src/helpers/number.helper";
import { shortAccount } from "src/helpers/blockchain.helper";
import { Link } from "react-router-dom";

const HeroContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(5, 2),
  // background: theme.palette.background.hero,
  color: theme.palette.primary.contrastText,
  //padding: "50px 0",
}));

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
  },
  block: {
    fontSize: "0.8rem",
  },
  fullWidth: {
    width: "100%",
    //border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: "20px",
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.3), 0px 10px 20px rgba(0,0,0,0.3)",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "5px 15px",
  },
  handlePill: {
    fontSize: "11px",
    top: 0,
    margin: 0,
  },
  priceBlock: {
    fontSize: "24px",
    lineHeight: "24px",
    marginBottom: "5px",
  },
}));

const Hero = ({ listing, onClickWatch, ...props }) => {
  const classes = useStyles();

  const { promised: isTokenLoading, getToken } = useTokenState(
    listing.collectionID
  );
  const { getExRateInUSD } = useCurrencyState();
  const { promised: isCollectionLoading, getCollection } = useCollectionState();

  const token = getToken(listing.collectionID, listing.tokenID);
  const collection = getCollection(listing.collectionID);

  return isTokenLoading || isCollectionLoading || !token ? null : (
    <HeroContainer className="hero-container">
      <Box className={classes.container}>
        <Grid
          container
          columnSpacing={5}
          alignItems="flex-start"
          className={classes.block}
        >
          <Grid item md={2}></Grid>
          <Grid item md={4} xs={12} className="hero-image">
            <Link to={`/listing/${listing.collectionID}/${listing.tokenID}`}>
              <img src={token.imageUrl} className={classes.fullWidth} />
            </Link>
          </Grid>
          <Grid item md={6} xs={12} className="hero-content">
            <div className="css-vurnku">
              <div className="css-1jm49l2" aria-expanded="false">
                <a className="css-h6hd16" href="#">
                  <div className={`css-1fp3cxy ${classes.inlineBlock}`}>
                    <div className="css-4cffwv">
                      <div
                        className={`username-tag css-5uuy7 ${classes.handlePill}`}
                      >
                        @{ process.env.REACT_APP_SITENAME }
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="css-f19vuz">
              <h2 className="css-1cce5m9">{collection.name}</h2>
              <h3>{token.itemName}</h3>
            </div>
            <Box sx={{ width: { xs: "100%", md: "75%" } }}>
              <Grid container sx={{ my: 3 }}>
                {listing.amount && (
                  <Grid item xs={6}>
                    {listing.purchased ? <p>Sold For</p> : <p>Buy Now</p>}
                    <h3 className={classes.priceBlock}>
                      {listing.amount} {listing.currency}
                    </h3>
                    <p>
                      $
                      {round(listing.amount * getExRateInUSD(listing.currency))}
                    </p>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <p>Owned by</p>
                  <h3 className={classes.priceBlock}>
                    {shortAccount(token.owner)}
                  </h3>
                </Grid>
              </Grid>
            </Box>
            <Box className="hero-action-buttons">
              <Button
                component={Link}
                to={`/listing/${listing.collectionID}/${listing.tokenID}`}
                variant="contained"
              >
                View Item
              </Button>
              {collection.youtube && (
                <Button
                  variant="contained"
                  sx={{ ml: 1 }}
                  onClick={() => onClickWatch(collection.youtube)}
                >
                  Watch Video
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </HeroContainer>
  );
};

export default Hero;
