import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { styled } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import SquareBlock from "src/components/frontend/SquareBlock";

const ProjectItemName = styled("h2")(({ theme }) => ({
  fontSize: "18px",
  [theme.breakpoints.up("sm")]: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

const ProjectItemDescription = styled("p")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

const useStyles = makeStyles(() => {
  return {
    projectImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      verticalAlign: "bottom",
    },
    projectBlock: {
      border: "1px solid #999",
      borderRadius: "10px",
      background: "#FFF",
      overflow: "hidden",
      boxShadow:
        "0px 0px 0px 0px rgba(0,0,0,0.3), 0px 10px 20px rgba(0,0,0,0.3)",
    },
    projectItemContent: {
      padding: "15px",
      background: "#7c366a",
      color: "#FFF",
    },
  };
});

const ProjectItem = ({ project, gridOptions, ...props }) => {
  const classes = useStyles();

  if (!project || !project.project_image) return null;

  return (
    <Grid item {...(gridOptions ? gridOptions : { xs: 12, sm: 6, lg: 4 })}>
      <Box>
        <Box className={classes.projectBlock}>
          <Link to={`/project/${project.id}`}>
            <SquareBlock>
              <img
                src={project.project_image}
                className={classes.projectImage}
              />
            </SquareBlock>
          </Link>
          <Box className={classes.projectItemContent}>
            <ProjectItemName>
              {project.project_name}
            </ProjectItemName>
            <ProjectItemDescription>
              {project.project_tagline}
            </ProjectItemDescription>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default ProjectItem;
