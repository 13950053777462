import { Outlet, useLocation } from "react-router-dom";
import { experimentalStyled } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import MainNavbar from "./components/MainNavbar";
import MainFooter from "./components/MainFooter";
import theme from "src/themes/vineart";
import "./css/MainLayout.css";
import "src/themes/vineart/style.css";
import { useEffect } from "react";
import { useSettingState } from "src/state";

const MainLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  width: "100%",
}));

const MainLayoutWrapper = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 10,
  minHeight: "900px",
});

const MainLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const MainLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  // height: "100%",
  // overflow: "auto",
});

const MainLayout = () => {
  const location = useLocation();
  const {promised: isLoadingSettings, getSetting} = useSettingState();
  // useEffect(() => {
  //   if (!isLoadingSettings) {
  //     const favicon_url = getSetting('website_icon');
  //     if (favicon_url) {
  //       const favicon = document.getElementById("favicon");
  //       favicon.href = favicon_url;
  //     } 
  //   }
  // }, [isLoadingSettings])
  return isLoadingSettings ? null : (
    <ThemeProvider theme={theme}>
      <MainLayoutRoot className={`page-${location.pathname === '/' ? 'home' : 'inner'}`} sx={{ background: `url(${getSetting('body_background')}) center bottom no-repeat #a26c8c` }}>
        <MainNavbar />
        <MainLayoutWrapper>
          <MainLayoutContainer>
            <MainLayoutContent>
              <Outlet />
            </MainLayoutContent>
          </MainLayoutContainer>
        </MainLayoutWrapper>
        <MainFooter />
      </MainLayoutRoot>
    </ThemeProvider>
  );
};

export default MainLayout;
