import { useSettingState } from 'src/state';
import logoSVG from '../themes/default/assets/img/logo.webp';

const Logo = (props) => {
  const {getSetting} = useSettingState();
  const logoURL = getSetting('website_logo');
  return logoURL ? (
    <img
      alt="Logo"
      src={logoURL}
      style={{ maxWidth: "100%" }}
      {...props}
    />
  ) : null
};

export default Logo;
