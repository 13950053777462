import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  experimentalStyled,
  Button,
  Box,
  Typography,
} from "@material-ui/core";
import { styled, alpha } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Logo from "../../components/Logo";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { shortAccount } from "src/helpers/blockchain.helper";
import { useContext } from "react";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Menu from "src/components/PopperMenu";
import { useGlobalState } from "src/state";
import HeaderModule from "src/components/frontend/partials/HeaderModule";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "1px solid #CCC",
  borderRadius: "10px",
  backgroundColor: alpha(theme.palette.common.white, 1),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.75),
  },
  // boxShadow: "0px 0px 0px 1px #eee, 0px 10px 20px #eee",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  // [theme.breakpoints.up("sm")]: {
  //   marginLeft: theme.spacing(3),
  // },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#939393",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const MainLayoutWrapper = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  //overflow: "hidden",
});

const MainNavbar = (props) => {
  const blockchainInfo = useContext(BlockchainContext);
  const { setRedirectToAfterConnect } = useGlobalState();
  const location = useLocation();

  const navigate = useNavigate();

  const connect = () => {
    setRedirectToAfterConnect(location.pathname);

    //if (blockchainInfo) blockchainInfo.connectToBlockchain();
    navigate("/connect-wallet");
  };

  const search = (value) => {
    navigate("/search/" + value);
  };

  return (
    <AppBar
      elevation={0}
      position="relative"
      color="transparent"
      sx={{ zIndex: 10 }}
      {...props}
    >
      <MainLayoutWrapper className="header">
        <Box className="container">
          <Toolbar
            className="header-toolbar"
            sx={{ minHeight: "none", width: "100%", padding: "0 !important", my: 1 }}
          >
            <RouterLink className="logo-container" to="/">
              <Logo />
            </RouterLink>
            <Box className="header-searchBar">
              <Search
                sx={{ display: { xs: "none", md: "block" } }}
                className="search-bar"
              >
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search here"
                  inputProps={{ "aria-label": "search" }}
                  onKeyPress={(e) => {
                    const pressed = e.keyCode || e.which;
                    if (pressed === 13) search(e.target.value);
                  }}
                />
              </Search>
              <Box>
                <Box className="header-icons">
                  {/* {blockchainInfo.account ? (
                  <Button
                    variant="contained"
                    style={{
                      width: "300px",
                      borderRadius: "20px",
                      textTransform: "none",
                      whiteSpace: "nowrap",
                      backgroundColor: "#DDD",
                    }}
                    sx={{ display: { xs: "none", md: "block" } }}
                  >
                    Connected as {shortAccount(blockchainInfo.account)}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      width: "300px",
                      borderRadius: "20px",
                      textTransform: "none",
                      whiteSpace: "nowrap",
                    }}
                    onClick={connect}
                  >
                    Connect your Wallet
                  </Button>
                )} */}
                  <Menu
                    title={<AccountCircleIcon fontSize="large" />}
                    options={[
                      {
                        text: "Profile",
                        action: blockchainInfo.account
                          ? () => navigate("/profile/settings")
                          : connect,
                      },
                      {
                        text: "Favourites",
                        action: blockchainInfo.account
                          ? () => navigate("/profile/favourites")
                          : connect,
                      },
                      process.env.REACT_APP_ALLOW_ADD_COLLECTION ? {
                        text: "My Collections",
                        action: blockchainInfo.account
                          ? () => navigate("/profile/collections")
                          : connect,
                      } : null,
                    ]}
                  />
                  <Menu
                    title={
                      <AccountBalanceWalletOutlinedIcon fontSize="large" />
                    }
                    options={[
                      {
                        text: blockchainInfo.account
                          ? `Connected as ${shortAccount(
                              blockchainInfo.account
                            )}`
                          : "Connect your wallet",
                        action: blockchainInfo.account ? null : connect,
                      },
                    ]}
                  />
                </Box>
              </Box>
            </Box>
          </Toolbar>
          <Search
            sx={{ display: { xs: "block", md: "none" } }}
            className="search-bar"
          >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search here"
              inputProps={{ "aria-label": "search" }}
              onKeyPress={(e) => {
                const pressed = e.keyCode || e.which;
                if (pressed === 13) search(e.target.value);
              }}
            />
          </Search>

          {location.pathname === '/' && <HeaderModule />}
        </Box>
      </MainLayoutWrapper>
    </AppBar>
  );
};

export default MainNavbar;
