import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
// import { useTokenState, useCurrencyState } from "src/state";
// import { round } from "src/helpers/number.helper";
import { Link } from "react-router-dom";
import SquareBlock from "src/components/frontend/SquareBlock";
import { useSettingState } from "src/state";

const useStyles = makeStyles(() => {
  return {
    listingImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      verticalAlign: "bottom",
    },
    listingBlock: {
      border: "1px solid #999",
      borderRadius: "10px",
      background: "#FFF",
      overflow: "hidden",
      boxShadow:
        "0px 0px 0px 0px rgba(0,0,0,0.3), 0px 10px 20px rgba(0,0,0,0.3)",
    },
    listingItemContent: {
      padding: "15px",
    },
    listingItemDescription: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    listingItemPrice: {
      padding: "15px",
      background: "#7c366a",
      color: "#FFF",
    },
  };
});

const TokenItem = ({
  collectionID,
  token,
  name,
  description,
  linkTo,
  ...props
}) => {
  const classes = useStyles();

  if (!token || !token.imageUrl) return null;

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Box>
        <Box className={classes.listingBlock}>
          <Link
            to={linkTo ? linkTo : `/listing/${collectionID}/${token.tokenID}`}
          >
            <SquareBlock>
              <img src={token.imageUrl} className={classes.listingImage} />
            </SquareBlock>
          </Link>
          <Box className={classes.listingItemContent}>
            <h2>{name ? name : token.itemName}</h2>
            <p className={classes.listingItemDescription}>
              {description ? description : `@${process.env.REACT_APP_SITENAME}`}
            </p>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default TokenItem;
