import ipfs from "src/plugins/ipfs";

export const readBlobToBuffer = (file) => new Promise((resolve, reject) => {
  if (file) {
    const reader = new window.FileReader()
    reader.readAsArrayBuffer(file)
    reader.onloadend = () => {
      resolve(Buffer(reader.result));
    }
  } else {
    reject('Must provide a file object');
  }
});

export const uploadFileToIPFS = (fileBuffer) => new Promise((resolve, reject) => {
  ipfs.files.add(fileBuffer, (error, result) => {
    if (error) {
      reject(error)
    }

    resolve(result[0].hash);
  })
})